import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DuePaymentModal from "./DuePaymentModal"; // Import the modal component
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ChangePackage from '../MemberList/Components/ChangePackage';
import ChangePackagePt from '../MemberList/Components/ChangePackagePt.jsx';
import RepeatIcon from "@mui/icons-material/Repeat";
import { TimePicker } from "@mui/x-date-pickers";

import Drawer from "@mui/material/Drawer";
import { Avatar, Divider, ButtonGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from "../Includes/Header";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { useEffect, useRef } from "react";
import {
  Card,
  Button,
  Grid,
  Tabs,
  Tab,
  TextField,
  FormControl,
  Snackbar,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Menu from "@mui/material/Menu";
import PaymentIcon from "@mui/icons-material/Payment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";
import PaymentHistory from "./PaymentHistory";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import RenewIcon from "@mui/icons-material/Autorenew"; // Make sure to import the correct renewal icon

import {
  retrieveGymMember,
  PtPackageDropdown,
  PtTrainerDropdown,
  PtPackageDropdownvalue,
  TrainerPayment,
  YearlyPackageAdd,
  MembershipPause,
  MembershipResume,
} from "../Apihelper/MemberDashboard";
import { PtduePaymentClear } from "../Apihelper/DueUserApi";
import {
  PackageadmissionDropdown,
  PackageAdmissionDropdownvalue,
} from "../Apihelper/AdmissionValueApi.js";
import {
  createNewMember,
  AutoMembershipNumber,
  PackageDropdownafteradmission,
  PackageDropdownvalueafteradmission,
} from "../Apihelper/MemberApiHelpr";
import {
  MembeRepeatPackage,
  MembeRenwalPackage,
  TrainerChange,
} from "../Apihelper/ApiHelperPayment";
import { useNavigate } from "react-router-dom";
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  position: "relative",
  marginBottom: "20px",
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const InfoBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.common.white,
}));

const DetailDialog = ({ open, handleClose, data }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <StyledDialogTitle>
        Personal Details
        <CloseButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </CloseButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Avatar
            src={window.constants.asset_path + data.image} // Adjust based on your data structure
            alt="Profile"
            sx={{ width: 80, height: 80, mr: 2 }}
          />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {data.name}
          </Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <InfoBox>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Phone:
          </Typography>
          <Typography variant="body2">{data.phone}</Typography>
        </InfoBox>
        <InfoBox>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Email:
          </Typography>
          <Typography variant="body2">{data.email}</Typography>
        </InfoBox>
        <InfoBox>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Address:
          </Typography>
          <Typography variant="body2">{data.address}</Typography>
        </InfoBox>
        <InfoBox>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Dob:
          </Typography>
          <Typography variant="body2">{data.dob}</Typography>
        </InfoBox>
        <InfoBox>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Alternate phone:
          </Typography>
          <Typography variant="body2">{data.alternate_phone}</Typography>
        </InfoBox>
        {/* Add more fields as needed */}
      </StyledDialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const drawerWidth = 292;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function MemberDetails(props) {

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [Dropdowndata, setDropdowndata] = useState([]); // Default to 'success'
  const { window1 } = props;
  const conversation_id = location.state?.id;

  const dateRef = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [renwalloading, setrenwalLoading] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [fetchdatarow, setetchdatarow] = useState([]);
  const [paymentrow, setpaymentrow] = useState([]);
  const [ptpaymentrow, setptpaymentrow] = useState([]);
  const [yearlymembership, setyearlymembership] = useState([]);
  const [age, setAge] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [packageamount, setpackageamount] = useState(""); // Default to 'success'
 
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const [value, setValue] = React.useState("");
  const [resumeDate, setResumeDate] = useState(null); // Define resumeDate state variable
  const [dialogOpen, setDialogOpen] = useState(false);
  const [opendue, setOpendue] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [useopen, setuseopen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [membershipExpired, setMembershipExpired] = useState(false);
  const [repeatOpen, setRepeatOpen] = useState(false);
  const [repeatOffer, setRepeatOffer] = useState("");
  const [packageIdrepeat, setPackageIdrepeat] = useState(null); // State to store package ID
  const [repeatModeOfPayment, setRepeatModeOfPayment] = useState(""); // State to store mode of payment
  const [pauseOrResume, setPauseOrResume] = useState(true);
  const [isResumeModal, setIsResumeModal] = useState(false);
  const [isAddPTPModal, setIsAddPTPModal] = useState(false);
  const [isAddYMPModal, setIsAddYMPModal] = useState(false);
  const [isPauseModal, setIsPauseModal] = useState(false);
  const [Dropdowndatamember, setDropdowndatamember] = useState([]);
  const [renewalAmount, setRenewalAmount] = useState('');
  const [renewalType, setRenewalType] = useState('');
  const [Dropdownadmissiondata, setDropdownadmissiondata] = useState([]); // Default to 'success'
  const [Dropdowndatatrainer, setDropdowndatatrainer] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

const [openChangeModal, setOpenChangeModal] = useState(false);
const [openChangeModalpt, setOpenChangeModalpt] = useState(false);
const [selectedPaymentData, setSelectedPaymentData] = useState(null);
const [selectedPaymentDataPt, setSelectedPaymentDataPt] = useState(null);
setSelectedPaymentData
const handleOpenChangeModal = (paymentData) => {
  setSelectedPaymentData(paymentData);
  setOpenChangeModal(true);
};

const handleCloseChangeModal = () => {
  setOpenChangeModal(false);
  setSelectedPaymentData(null);
};

const handleOpenChangeModalPt = (paymentData) => {
  setSelectedPaymentDataPt(paymentData);
  setOpenChangeModalpt(true);
};

const handleCloseChangeModalpt = () => {
  setOpenChangeModalpt(false);
  setSelectedPaymentDataPt(null);
};



const handleCloseDialogs = () => {
  setIsDialogOpen(false); // Close the dialog
};
  // Function to handle the dialog open and store the package_id (payment.id)
  const repeatHandleOpen = (paymentrow) => {
    const selectedPayment = paymentrow.find((payment) => payment.package_id);
    const membershipExpiredStatus = paymentrow.some((payment) => payment.admission_expired);
  console.log(membershipExpiredStatus);
    if (selectedPayment) {
      setPackageIdrepeat(selectedPayment.package_id);
      setMembershipExpired(membershipExpiredStatus); // Set membership expiration status
      setRepeatOpen(true);
    } else {
      console.error("Payment not found in paymentrow.");
    }
  };
  
  const handleNavigate = (invoiceNumber, conversationId) => {
    navigate("/invoice", {
      state: {
        invoice_number: invoiceNumber,
        conversation_id: conversation_id,
      },
    });
  };
  
  // Function to handle the dialog open

  // Function to handle the dialog close
  const repeatHandleClose = () => setRepeatOpen(false);

  const [formdueData, setFormdueData] = useState({
    member_id: conversation_id,
    package_id: "",
    total_payble_amount: "",
    payble_amount: "",
    offer: "",
    paying_amount: "",
    mode_of_payment: "",
    date_of_payment: new Date().toISOString().split('T')[0],
    start_date: "",
    end_date: "",
     membershipvalue:"",
           membership:""
  });

  const fetchDropdownAdmissiondata = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageadmissionDropdown(userToken, userId);

      if (res.code == "200") {
        setDropdownadmissiondata(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  useEffect(() => {
    if (conversation_id) {
      setFormdueData((prevState) => ({
        ...prevState,
        member_id: conversation_id,
      }));
    }
  }, [conversation_id]); // Update when conversation_id is available

  // Open dialog with specific data
  const handlerenwalChange = (data) => {
    // Assuming data is an array, access the first item
    const paymentData = data[0];
    const membershipExpiredStatus = data.some((payment) => payment.admission_expired);
  
    // Set membership expired status
    setMembershipExpired(membershipExpiredStatus);
  
    // Extract end_date and start_date from the paymentData object
    const { end_date, start_date } = paymentData; // Ensure both are part of the data passed
  
    console.log("End Date:", end_date); // Log end_date to the console
    console.log("Start Date:", start_date); // Log start_date to the console
  
    // Safely format start_date
    let formattedStartDate = null;
    if (end_date) {
      const dateParts = end_date.split('-'); // Split the date (if in d-m-y format)
      if (dateParts.length === 3) {
        const [day, month, year] = dateParts;
        const validDate = new Date(`${year}-${month}-${day}`); // Convert to yyyy-mm-dd
        if (!isNaN(validDate.getTime())) {
          formattedStartDate = validDate.toISOString().split('T')[0];
        } else {
          console.error("Invalid start_date:", end_date);
        }
      } else {
        console.error("Invalid start_date format:", end_date);
      }
    }
  
    // Update form data with end_date and formatted start_date
    setFormdueData((prevData) => ({
      ...prevData,
      end_date, // Set the end_date in form data
      start_date: formattedStartDate, // Include the formatted start_date
    }));
  
    // Open the dialog
    setuseopen(true);
  };
  

  // Close dialog
  const handlerenwalclose = () => setuseopen(false);

  // Handle input change
  const handleChangerenwal = (e) => {
    const { name, value } = e.target;

    setFormdueData((prevData) => {
      let updatedData = { ...prevData, [name]: value };

      // Ensure 'payble_amount' starts as the same as 'total_payble_amount'
      if (!prevData.payble_amount) {
        updatedData.payble_amount = prevData.total_payble_amount;
      }

      // If 'offer' changes, update the payable amount based on the total payable amount and the offer
      if (name === "offer") {
        const updatedPayable = prevData.total_payble_amount - value;
        updatedData.paying_amount = updatedPayable >= 0 ? updatedPayable : 0;
      }

      if (name === "payble_amount") {
        updatedData.payble_amount = prevData.total_payble_amount; // Keep payble_amount same as total_payble_amount
      }
      return updatedData;
    });
  };

  const handlerepeatSubmit = async () => {
    setrenwalLoading(true); // Hide loader when submission finishes
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const numericRepeatOffer = Number(repeatOffer);

      const response = await MembeRepeatPackage(
        userToken,
        userId,
        conversation_id,
        packageIdrepeat,
        repeatModeOfPayment,
        numericRepeatOffer,
        renewalType,
        renewalAmount,
      );

      if (response.code === 200)
        {
        setSnackbarMessage(response.message);
        setSnackbarSeverity("success");
        setOpenToast(true); // Show success Snackbar
        repeatHandleClose(); // Close the modal
        setPackageIdrepeat(null); // Store the package_id in state
        setRepeatOffer("");
        setRepeatModeOfPayment("");
        fetchData();

      } else {
        setSnackbarMessage(
          response.message || "Error occurred while renewing package."
        );
        setSnackbarSeverity("error");
        setOpenToast(true); // Show error Snackbar
      }
    } catch (error) {
      setSnackbarMessage("Failed to rene: " + error.message);
      setSnackbarSeverity("error");
      setOpenToast(true); // Show error Snackbar
    } finally {
      setrenwalLoading(false); // Hide loader when submission finishes
    }

    repeatHandleClose(); // Close the dialog after submission
  };

  const handleSave = async () => {
    setrenwalLoading(true); // Show loader when form submission starts
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const response = await MembeRenwalPackage(
        userToken,
        userId,
        conversation_id,
        formdueData
      );

      if (response.code == "200") {
        setSnackbarMessage(response.message);
        setSnackbarSeverity("success");
        setOpenToast(true); // Show success Snackbar
        handlerenwalclose(); // Close the modal
        fetchData();
        setFormdueData({
          member_id: conversation_id,
          package_id: "",
          total_payble_amount: "",
           payble_amount: "",
           offer: "",
           paying_amount: "",
           mode_of_payment: "",
           date_of_payment: "",
           start_date: "",
           end_date: "",
            membershipvalue:"",
           membership:""
        });
      } else {
        setSnackbarMessage(
          response.message || "Error occurred while renewing package."
        );
        setSnackbarSeverity("error");
        setOpenToast(true); // Show error Snackbar
      }
    } catch (error) {
      setSnackbarMessage("Failed to renew package: " + error.message);
      setSnackbarSeverity("error");
      setOpenToast(true); // Show error Snackbar
    } finally {
      setrenwalLoading(false); // Hide loader when submission finishes
    }
  };
  const handleClickOpendue = (payment) => {
    setSelectedPayment(payment);

    setOpendue(true);
  };

  const handleClosedue = () => {
    setOpendue(false);
  };
  const handleDuePayment = async (formData) => {
    setLoading(true); // Optionally, you can remove this if it's not needed
    console.log("Payment processed successfully:", formData);
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const userId = userDataObj.id;
    try {
      const result = await PtduePaymentClear(userToken, userId, formData);
      console.log("Payment processed successfully:", result);
      handleSnackbarOpen(result.message, "success");
      handleClosedue();
      setLoading(false); // Optionally, you can remove this if it's not needed
      fetchData();
    } catch (error) {
      console.error("Failed to process due payment:", error);
      handleSnackbarOpen(result.message, "success");
      setLoading(false); // Optionally, you can remove this if it's not needed
    }
  };
  const handleOpenDialog = () => {
    setDialogData(fetchdatarow); // Set the card data
    setDialogOpen(true);
  };

  const handleCloseDialog = () => setDialogOpen(false);
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };
  const [FormData, setFormData] = useState({
    member_id: "",
    discount: 0,
    package: "",
    packagevalue: "",
    totalpaybleamount: "",
    discount: "",
    payableamount: "",
    modeofpayment: "",
    payingamount: "",
    dueamount: "",
    activationdate: null,
    dateofpayment: null,
    trainer:"",
    slot:"",
    startTime: null,  // Added startTime
    endTime: null,    // Added endTime
  });

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide all borders for all children
    "& td, & th": {
      border: 0,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));



  const openPauseModal = () => {
    setIsPauseModal(true);
  };
  const closePauseModal = () => {
    setIsPauseModal(false);
  };
  // const handleClickPause = () => {
  //     setPauseOrResume(false)
  //     setIsPauseModal(false);

  // }

  
  const closeResumeModal = () => {
    setIsResumeModal(false);
  };
  const handleClickResume = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata

      const res = await MembershipResume(
        userToken,
        conversation_id,
        resumeDate,
        userId
      );

      if (res.code == "200") {
        console.log(res.data);
        fetchData();
        setPauseOrResume(true);
        setIsResumeModal(false);
        handleSnackbarOpen(res.message, "success");

        setLoading(false); // Optionally, you can remove this if it's not needed
      } else {
        handleSnackbarOpen(res.message, "error");
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };

  
  const openAddPTPModal = () => {
    fetchDropdowndata();
    setIsAddPTPModal(true);
  };
  const closeAddPTPModal = () => {
    setIsAddPTPModal(false);
    setFormData({
      member_id: "",
      package: "",
      packagevalue: "",
      totalpaybleamount: "",
      discount: "",
      payableamount: "",
      modeofpayment: "",
      payingamount: "",
      activationdate: null,
      dateofpayment: null,
     
      trainer:"",
    slot:"",
    startTime: null,  // Added startTime
    endTime: null,    // Added endTime
    });
    setStartTime(null);
    setEndTime(null);
    setSubmitted(false);
  };
  // const handleClickAddPTP = () => {
  //     // setPauseOrResume(true)
  //     setIsAddPTPModal(false);

  // }
  

  const openAddYMPModal = () => {
    setIsAddYMPModal(true);
  };
  const closeAddYMPModal = () => {
    setIsAddYMPModal(false);
    setEndDate(null);
    setStartDate(null);
  };

  const [joiningdateadd, setjoiningdateadd] = useState(null);
  const fetchData = async (value = "") => {
    setLoading(true);
    // setHasMore(true);
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
      const limit = 10; // Example limit
      const index = 0; // Example index
      // console.log(conversation_id);
      const res = await retrieveGymMember(userToken, conversation_id, userId);

      if (res.code == "200") {
        setetchdatarow(res.data);
        const joiningDate = new Date(res.data.joining_date);
        const formattedJoiningDate = `${String(joiningDate.getDate()).padStart(2, '0')}/${String(joiningDate.getMonth() + 1).padStart(2, '0')}/${joiningDate.getFullYear()}`;
    
        setjoiningdateadd(formattedJoiningDate); // Set formatted date
    
        setpaymentrow([res.data.payments]);
        console.log(res.data.payments);
        setptpaymentrow([res.data.pt_data]);
        setyearlymembership([res.data.yearly_package_payments]);

        console.log(
          "yearly_package_payments:",
          res.data.yearly_package_payments
        );
        // return false;
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        handleSnackbarOpen(res.message, "error");
        // console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  useEffect(() => {
    fetchData();
  }, [conversation_id]);
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate("/");
    }
  }, []);
  const fetchDropdowndata = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PtTrainerDropdown(userToken, userId);
        
      if (res.code == "200") {
        setDropdowndatatrainer(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };

  const fetchDropdowndatatrainer = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PtPackageDropdown(userToken, userId);

      if (res.code == "200") {
        setDropdowndata(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  const handlePackageChange = (paymentData) => {
    // Replace 'navigate' with your routing logic (e.g., useHistory in React Router v5, useNavigate in v6)
    navigate("/change-package-", { state: { paymentData } });
  };
  
  const handlepayblevalueChange = async (e) => {
    // Update state with the selected package
    const selectedPackageId = e.target.value;
    setFormData({ ...FormData, package: selectedPackageId });

    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PtPackageDropdownvalue(
        userToken,
        userId,
        selectedPackageId
      );

      if (res.code == "200") {
        const packageAmount = res.data;
        setFormData((prevState) => ({
          ...prevState,
          packagevalue: packageAmount,
        }));

        setpackageamount(packageAmount);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if necessary
    }
  };
  const [prevPackageAmount, setPrevPackageAmount] = useState(0);
  const handlepayblevalueChangemember = async (e) => {
    const selectedPackageId = e.target.value;
    const selectedPackage = Dropdowndata.find(
      (row) => row.id === selectedPackageId
    );

    setFormdueData({
      ...formdueData,
      package_id: selectedPackageId,
      packagename: selectedPackage?.name || "",
    });

    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageDropdownvalueafteradmission(
        userToken,
        userId,
        selectedPackageId,
        conversation_id,
      );

      if (res.code == "200") {
        const packageAmount = res.data.package_amount || 0;
  
        setFormdueData((prevState) => {
          // Subtract the previous amount and add the new one
          const total_payble_amount =
            (parseFloat(prevState.total_payble_amount) || 0) - prevPackageAmount + packageAmount;
  
          return {
            ...prevState,
            total_payble_amount,
            payble_amount: total_payble_amount, // Sync with total_payble_amount
          };
        });
  
        // Update the previous amount to the current selection's amount
        setPrevPackageAmount(packageAmount);
      } else {
        console.error("API error:", res.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTotalPayableAmountChange = (e) => {
    const enteredValue = e.target.value;

    // If entered value is greater than the package amount, set it to the package amount
    const totalPayableAmount =
      enteredValue > packageamount ? packageamount : enteredValue;

    // Update state with the total payable amount
    setFormData({ ...FormData, totalpaybleamount: totalPayableAmount });
  };

  // Function to generate the helper text
  const generateHelperText = () => {
    return FormData.totalpaybleamount > packageamount
      ? "Total payable amount cannot exceed the package value"
      : "";
  };
  const getMinStartDate = () => {
    const { end_date } = formdueData; // Get end_date from form data

    console.log("Original end_date:", end_date); // For debugging

    if (end_date) {
      const [day, month, year] = end_date.split("-");
      const parsedEndDate = new Date(`${year}-${month}-${day}`);

      console.log("Parsed end_date (Date Object):", parsedEndDate); // For debugging

      const today = new Date();
      console.log("Today's date:", today); // For debugging

      const minStartDate = parsedEndDate >= today ? end_date : ""; // Return empty string if end_date is in the past
      console.log("Min Start Date:", minStartDate); // For debugging
      return minStartDate.split("-").reverse().join("-"); // Return in YYYY-MM-DD format
    }

    return ""; // Default to empty if no end_date
  };
  const handleChangePaymentMode = (event) => {
    const { name, value } = event.target;
    setFormdueData({
      ...formdueData,
      [name]: value
    });
  };
  
  const handleDiscountChange = (e) => {
    // Parse the discount value as a number, defaulting to 0 if it's empty or not a valid number
    const discount = parseFloat(e.target.value) || 0;

    // Calculate the new payable amount after deducting the discount
    const newPayableAmount = FormData.totalpaybleamount - discount;

    // Update the form data with the new discount and payable amount
    setFormData({
      ...FormData,
      discount: discount,
      payingamount: newPayableAmount,
    });
  };
  const handleClickAddPTP = async () => {
    setSubmitted(true);
  
    // Update FormData with member ID, start time, and end time
    const updatedFormData = {
      ...FormData,
      startTime: FormData.startTime ? FormData.startTime.format("HH:mm") : "",
      endTime: FormData.endTime ? FormData.endTime.format("HH:mm") : "",
      slot:
        FormData.startTime && FormData.endTime
          ? `${FormData.startTime.format("HH:mm")}-${FormData.endTime.format(
              "HH:mm"
            )}`
          : "",
    };
  
    // Set the updated FormData to state
    setFormData(updatedFormData);
  
    // Define required fields for validation
    const requiredFields = [
      "packagevalue",
      "package",
      "totalpaybleamount",
      "modeofpayment",
      "payingamount",
      "activationdate",
      "dateofpayment",
      "trainer",
    "slot",
    "startTime",  // Added startTime
    "endTime",    // Added endTime
    ];
  
    // Check for empty fields
    const emptyFields = requiredFields.filter((field) => !updatedFormData[field]);
    if (emptyFields.length > 0) {
      handleSnackbarOpen("Please fill all required fields.", "error");
      return;
    }
  
    // Ensure end time is later than start time
    if (startTime && endTime && endTime.isBefore(startTime)) {
      handleSnackbarOpen("End Time must be later than Start Time", "error");
      return;
    }
  
    // Fetch user token from local storage
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
  
    try {
      // Send request to TrainerPayment API with updated FormData
      const res = await TrainerPayment(userToken, conversation_id, updatedFormData);
      if (res.code =="200") {
        closeAddPTPModal();
        handleSnackbarOpen("added sucessfully", "success");
  
        // Reset form data including start and end times
        setFormData({
          member_id: "",
          package: "",
          packagevalue: "",
          totalpaybleamount: "",
          discount: "",
          payableamount: "",
          modeofpayment: "",
          payingamount: "",
          activationdate: null,
          dateofpayment: null,
          trainer:"",
          slot:"",
          startTime: null,  // Added startTime
          endTime: null,    // Added endTime
        });
  
        // Refresh data
        fetchData();
      } else {
        handleSnackbarOpen(res.message, "error");
      }
    } catch (error) {
      console.error(error); // Handle error
      handleSnackbarOpen("An error occurred. Please try again.", "error");
    }
  };
  

  const fetchDropdowndatarenwal = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageDropdownafteradmission(userToken, userId);

      if (res.code == "200") {
        setDropdowndatamember(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  useEffect(() => {
    fetchDropdowndatarenwal();
    fetchDropdownAdmissiondata();
    fetchDropdowndatatrainer();
  }, []);
  const tabStyle = (isActive) => ({
    textDecoration: "none",
    flexGrow: 1,
    textTransform: "none",
    fontWeight: "bold",
    marginRight: "10px",
    backgroundColor: isActive ? "#D8E2FF" : "inherit",
    borderRadius: "10px 10px 0px 0px",
    borderBottom: isActive ? "2px solid #005AC1" : "none",
    color: isActive ? "#005AC1" : "inherit",
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date) {
      const newEndDate = dayjs(date).add(365, "day").toDate(); // Add 365 days using dayjs
      setEndDate(newEndDate);
      console.log(newEndDate);
    } else {
      setEndDate(null);
    }
  };

  const handleClickAddYMP = async () => {
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;

    try {
      const res = await YearlyPackageAdd(
        userToken,
        conversation_id,
        startDate,
        endDate
      );
      if (res.code == "200") {
        closeAddPTPModal();
        handleSnackbarOpen(res.message, "success");
        setEndDate(null);
        setStartDate(null);
        setIsAddYMPModal(false);
        fetchData();
      } else {
        handleSnackbarOpen(res.message, "error");
        //   console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error); // Handle error
    }
  };

  const handlePayment = (paymentData) => {
    console.log(paymentData);
    navigate("/payment", { state: { paymentData } });
  };
  const [selectedDate, setSelectedDate] = useState(null); // State variable to hold the selected date

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update the selected date when user selects a date
  };
  const handleClickPause = async () => {
    if (selectedDate) {
      try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata

        const res = await MembershipPause(
          userToken,
          conversation_id,
          selectedDate,
          userId
        );

        if (res.code == "200") {
          console.log(res.data);
          setPauseOrResume(false);
          setIsPauseModal(false);
          fetchData();
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          handleSnackbarOpen(res.message, "error");
          // console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error);
        // Handle errors
      }
    }
  };

  const handlepayblevalueadmissionChange = async (e) => {
    const selectedPackageId = e.target.value;
    const selectedPackage = Dropdownadmissiondata.find(
      (row) => row.id === selectedPackageId
    );
  
    // setFormdueData({
    //   ...formdueData,
    //   membership: selectedPackageId,
    //   membership_packagename: selectedPackage?.name || "",
    // });
    setRenewalType(selectedPackageId);
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
  
      const res = await PackageAdmissionDropdownvalue(
        userToken,
        userId,
        selectedPackageId
      );
  
      if (res.code == "200") {
        const packageAmount = parseFloat(res.data) || 0;
  
      
        // Update the previous admission amount to the current selection's amount
        setRenewalAmount(packageAmount);
      } else {
        console.error("Error response:", res.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [prevAdmissionAmount, setPrevAdmissionAmount] = useState(0);
  const handlepayblevalueadmissionrepeat = async (e) => {
    const selectedPackageId = e.target.value;
    const selectedPackage = Dropdownadmissiondata.find(
      (row) => row.id === selectedPackageId
    );
  
    setFormdueData({
      ...formdueData,
      membership: selectedPackageId,
      membership_packagename: selectedPackage?.name || "",
    });
  
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
  
      const res = await PackageAdmissionDropdownvalue(
        userToken,
        userId,
        selectedPackageId
      );
  
      if (res.code == "200") {
        const packageAmount = parseFloat(res.data) || 0;
  
        setFormdueData((prevState) => {
          // Subtract the previous admission amount and add the new one
          const total_payble_amount =
            (parseFloat(prevState.total_payble_amount) || 0) - prevAdmissionAmount + packageAmount;
  
          return {
            ...prevState,
            membershipvalue: packageAmount,
            total_payble_amount,
            payble_amount: total_payble_amount, // Sync with total_payble_amount
          };
        });
  
        // Update the previous admission amount to the current selection's amount
        setPrevAdmissionAmount(packageAmount);
      } else {
        console.error("Error response:", res.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const [selectedPaymentId, setselectedPaymentId] = useState("");
  const [isChangeTrainerDialogOpen, setIsChangeTrainerDialogOpen] = useState(false); // Specific state name
  const [trainer, setTrainer] = useState("");
  const handleOpentrainerDialog = (id) => {
    setselectedPaymentId(id);
    setIsChangeTrainerDialogOpen(true);
    fetchDropdowndata(); // Call the function when the dialog opens
  };
  
  const handleClosetrainerDialog = () => setIsChangeTrainerDialogOpen(false);

  const handleTrainerChange = (event) => {
    setTrainer(event.target.value);
  };


  const handleTrainerChangeapi = async (formData) => {
    setLoading(true);
  
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const userId = userDataObj.id;
    try {
      const result = await TrainerChange(userToken, selectedPaymentId, trainer);
    
      handleSnackbarOpen(result.message, "success");
      handleClosetrainerDialog();
      setLoading(false); // Optionally, you can remove this if it's not needed
      fetchData();
    } catch (error) {
      console.error("Failed to process due payment:", error);
      handleSnackbarOpen(result.message, "success");
      setLoading(false); // Optionally, you can remove this if it's not needed
    }
  };
  return (
    <Box sx={{ display: "flex", minHeight: "100vh",  background: "linear-gradient(90deg, #ff7c43, #ffb547, #42a5f5, #66bb6a, #ab47bc)",
      backgroundSize: "300% 300%",
      animation: "gradientAnimation 10s ease infinite", }}>
      <Header />
      <CssBaseline />
      <style>
        {`
          @keyframes gradientAnimation {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
        `}
      </style>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: value === 0 ? "auto" : "hidden",
          padding: "20px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
             
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div style={{ display: "flex", flexGrow: 1, marginTop: "50px", }}>
            <div style={{ flex: 1, marginTop: "15px" }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Member Details
                  </Typography>
                  <Tabs
                    value={value}
                    onChange={handleChangetab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      sx={{
                        ...tabStyle(0 === value),
                      }}
                      label="Payment History"
                      {...a11yProps(0)}
                    />
                  </Tabs>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      fontWeight: 700,
                    }}
                    onClick={() => navigate("/memberlist")}
                  >
                    View List
                  </Button>
                </Box>
              </Box>
              <TabPanel value={value} index={0}>
                <PaymentHistory conversationId={conversation_id} />
              </TabPanel>
              <div style={{ display: value === 0 ? "none" : "" }}>
                <Grid container spacing={2}>
                  {/* First Card */}
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        background: "linear-gradient(135deg, #43cea2, #185a9d)", // Soft mint green transitioning to a deep blue
                        borderRadius: "20px",
                        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
                        overflow: "hidden",
                        transition:
                          "transform 0.4s, box-shadow 0.4s, background 0.4s",
                        "&:hover": {
                          transform: "scale(1.06)",
                          boxShadow: "0 20px 40px rgba(0, 0, 0, 0.3)",
                          background:
                            "linear-gradient(135deg, #56ab2f, #a8e063)", // Brighter green tones on hover
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          p: 3,
                          background: "rgba(255, 255, 255, 0.1)",
                          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                        }}
                      >
                          <img
                          src={window.constants.asset_path + fetchdatarow.image}
                          alt="Profile"
                          style={{
                            height: "97px",
                            width: "97px",
                            marginRight: "20px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            border: "4px solid #fff",
                            transition: "transform 0.4s",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="h5"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            paddingLeft: "10px",
                          }}
                        >
                          {fetchdatarow.membership_number}
                        </Typography>

                      
                      </Box>

                      <Grid container spacing={2} sx={{ paddingX: "20px" }}>
                        {[
                          { label: "Name", value: fetchdatarow.name },
                          { label: "Phone", value: fetchdatarow.phone },
                          {
                            label: "Alternative No",
                            value: fetchdatarow.alternate_phone,
                          },
                          { label: "Email", value: fetchdatarow.email },
                        ].map((item, index) => (
                          <Grid item xs={3} key={index}>
                            <Typography
                              fontWeight="bold"
                              sx={{
                                fontSize: "15px",
                                color: "#fff",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                color: "#ffebee",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.value}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>

                      <Grid
                        container
                        spacing={4}
                        sx={{ paddingX: "20px", mt: 1, mb: 1 }}
                      >
                        {[
                          { label: "Address", value: fetchdatarow.address },
                          { label: "Date of Birth", value: fetchdatarow.dob },
                          { label: "Gender", value: fetchdatarow.sex },
                          { label: "Height", value: fetchdatarow.height },
                        ].map((item, index) => (
                          <Grid item xs={3} key={index}>
                            <Typography
                              fontWeight="bold"
                              sx={{
                                fontSize: "15px",
                                color: "#fff",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                color: "#ffebee",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.value}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>

                      <Grid
                        container
                        spacing={4}
                        sx={{ paddingX: "20px", mt: 1, mb: 1 }}
                      >
                        {[
                          { label: "Weight", value: fetchdatarow.weight },
                          {
                            label: "Id Type",
                            value: fetchdatarow.identification_type,
                          },
                          {
                            label: "Id No",
                            value: fetchdatarow.identification_id,
                          },
                        ].map((item, index) => (
                          <Grid item xs={3} key={index}>
                            <Typography
                              fontWeight="bold"
                              sx={{
                                fontSize: "15px",
                                color: "#fff",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                color: "#ffebee",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.value}
                            </Typography>
                          </Grid>
                        ))}
                        <Grid item xs={3}>
                          <Button
                            onClick={handleOpenDialog}
                            variant="contained"
                            sx={{
                              fontWeight: 700,

                              "&:hover": {
                                transform: "scale(1.1)",
                                background: "#311b92",
                              },
                            }}
                          >
                            View
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  {paymentrow &&
                    paymentrow.map((payment, index) => (
                      <>
                        <Grid item xs={6}>
                          <Card
                            sx={{
                              background:
                                "linear-gradient(135deg, #00c6ff, #0072ff)",
                              borderRadius: "20px",
                              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
                              overflow: "hidden",
                              transition: "transform 0.4s, box-shadow 0.4s",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: "0 15px 40px rgba(0, 0, 0, 0.3)",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                p: 3,
                                background: "rgba(255, 255, 255, 0.1)",
                                borderBottom:
                                  "1px solid rgba(255, 255, 255, 0.2)",
                              }}
                            >
                              <Typography
                                variant="h5"
                                onClick={() => handlerenwalChange(paymentrow)}
                                sx={{
                                  color: "#fff",
                                  fontWeight: "bold",
                                  paddingLeft: "5px",
                                  textDecoration:"underline",
                                  cursor:"pointer"
                                }}
                                padding="20px 0px 0px 10px"
                              >
                                Package Payment
                              </Typography>
                              <IconButton
                                padding="20px 0px 0px 10px"
                                sx={{
                                  top: 10, // Adjust as needed
                                  right: 10, // Adjust as needed
                                  color: "#fff",
                                }}
                                onClick={() => handlerenwalChange(paymentrow)} // Implement handleRenewal function
                              >
                                <RenewIcon />
                              </IconButton>
                              <Button
                                variant="contained" // Consider using contained for more button-like appearance
                                onClick={() =>
                                  handleNavigate(payment.invoice_number)
                                }
                                style={{
                                  color: "#fff", // White text
                                  backgroundColor: "#28a745", // Green
                                  borderRadius: "4px",
                                  top: 8, // Adjust as needed
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  transition:
                                    "background-color 0.3s, transform 0.2s",
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#218838"; // Darker green on hover
                                  e.currentTarget.style.transform =
                                    "scale(1.05)"; // Slight zoom effect
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#28a745"; // Reset color
                                  e.currentTarget.style.transform = "scale(1)"; // Reset zoom
                                }}
                              >
                                <span style={{ marginRight: "4px" }}>📄</span>
                                inv-{payment.invoice_number}
                              </Button>

                              <Box>
                              
                              {payment.admission_payment === "no" && (
  <Button
    // Open dialog on button click
    onClick={() => repeatHandleOpen(paymentrow)} // Call the repeat package function
    sx={{
      textTransform: "none",
      whiteSpace: "nowrap",
      fontWeight: 700,
      marginTop: "15px",
    }}
    variant="contained"
    color="warning"
    endIcon={<RepeatIcon />} // Optional icon for repeat action
  >
    Repeat
  </Button>
)}

                              </Box>
                            </Box>

                            <Grid
                              container
                              spacing={4}
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                paddingX: "20px",
                              }}
                            >
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Joining Date
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {joiningdateadd}
                                  </Typography>
                                </Grid>
                              </Grid>
                             
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Paid Amount
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.paying_amount}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Due Amount
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.due}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={4}
                              sx={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                paddingX: "20px",
                              }}
                            >
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Payment Mode
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.mode_of_payment}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Payment Date
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.date_of_payment}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",

                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                     Expire Date
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",

                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.end_date}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
              <Grid item xs={12}>
                <Box>
                  <Button
                   onClick={() => handleOpenChangeModal(payment)}
                    sx={{
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      fontWeight: 700,
                      marginTop: "15px",
                    }}
                    variant="contained"
                    size="small"
                    color="secondary"
                    endIcon={<ChangeCircleIcon />} // Assuming ChangeCircleIcon is imported
                  >
                    Change
                  </Button>
                </Box>
              </Grid>
            </Grid>
                            </Grid>

                            <Grid
                              container
                              spacing={4}
                              sx={{ marginBottom: "10px", paddingX: "10px" }}
                            >
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Box>
                                    <Button
                                      onClick={() =>
                                        handlePayment({
                                          paymentData: {
                                            name: fetchdatarow.name,
                                            member_id: fetchdatarow.id,
                                            email: fetchdatarow.email,
                                            phone: fetchdatarow.phone,
                                            due: payment.due,
                                            payment_id: payment.id,

                                          },
                                        })
                                      }
                                      sx={{
                                        textTransform: "none",
                                        whiteSpace: "nowrap",
                                        fontWeight: 700,
                                        marginTop: "15px",
                                      }}
                                      variant="contained"
                                      endIcon={<PaymentIcon />}
                                    >
                                      Due Payment
                                    </Button>
                                  </Box>
                                </Grid>
                              </Grid>

                            
                              <Grid item xs={2}></Grid>
                              <Grid item xs={4}>
                                <Grid item xs={12}>
                                  <Box>
                                    {payment.expired === "Yes" ? (
                                      <Button
                                        color="error"
                                        sx={{
                                          textTransform: "none",
                                          whiteSpace: "nowrap",
                                          fontWeight: 700,
                                          marginTop: "15px",
                                        }}
                                        variant="contained"
                                        endIcon={<HourglassBottomIcon />}
                                      >
                                        Package Expired - {payment.expired_days}{" "}
                                        days ego
                                      </Button>
                                    ) : (
                                      <Button
                                        color="success"
                                        sx={{
                                          textTransform: "none",
                                          whiteSpace: "nowrap",
                                          fontWeight: 700,
                                          marginTop: "15px",
                                        }}
                                        variant="contained"
                                        endIcon={<HourglassBottomIcon />}
                                      >
                                        Package Active - {payment.days_left}{" "}
                                        days left
                                      </Button>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </>
                    ))}
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        background: "linear-gradient(135deg, #ff00cc, #333399)",
                        borderRadius: "20px",
                        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
                        overflow: "hidden",
                        transition: "transform 0.4s, box-shadow 0.4s",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0 15px 40px rgba(0, 0, 0, 0.3)",
                        },
                      }}
                    >
                      {ptpaymentrow.length > 0 ? (
                        ptpaymentrow.map((payment, index) => (
                          <React.Fragment key={index}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                p: 3,
                                background: "rgba(255, 255, 255, 0.1)",
                                borderBottom:
                                  "1px solid rgba(255, 255, 255, 0.2)",
                              }}
                            >
<Typography
  variant="h6"
  sx={{
    color: "white",
    fontWeight: "bold",
    padding: "20px 0px 0px 10px",
    cursor: "pointer",
    textDecoration: "underline",
  }}
>
  <Tooltip title={`Navigate to Invoice: inv-${payment.invoice_number}`} arrow>
    <span
      onClick={() => handleNavigate(payment.invoice_number)}
      style={{
        color: "inherit",
        cursor: "pointer",
      }}
    >
      Trainer Package
    </span>
  </Tooltip>
</Typography>

                              <Typography
                                variant="h6"
                                sx={{ color: "white", fontSize: "16px" }}
                                padding="20px 0px 0px 10px"
                              >
                                
                                
                                <Button
                                size="small"
                                  sx={{
                                    textTransform: "none",
                                    whiteSpace: "nowrap",
                                    fontWeight: 700,
                                  }}
                                  variant="contained"
                                 
                                  onClick={() => handleOpentrainerDialog(payment.id)} // Pass payment.id here
                                >
                                 Change Trainer
                                </Button>

          
          
                              </Typography>

                            <Button
            
              sx={{
                textTransform: "none",
                whiteSpace: "nowrap",
                fontWeight: 700,
                marginTop: "20px",
                fontWeight: "bold",
              }}
              variant="contained"
              size="small"
              color="warning"
              endIcon={<ChangeCircleIcon />}
           
            onClick={() => handleOpenChangeModalPt(payment)}
            >
              Change
            </Button>
           

            
                            </Box>
             
                            <Grid
                              container
                              spacing={4}
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                paddingX: "20px",
                              }}
                            >
                            
                               <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "16px",
                                      textTransform: "none",
                                      color: "#fff",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    package
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.trainer_package}
                                  </Typography>
                                </Grid>
                              </Grid> 
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "16px",
                                      textTransform: "none",
                                      color: "#fff",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Paying Amount
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.paying_amount}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "16px",
                                      textTransform: "none",
                                      color: "#fff",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Due Amount
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.due}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                             
                              <>
                                    <Button
                                      color="error"
                                        size="small"
                                      sx={{
                                        textTransform: "none",
                                        fontWeight: 200,
                                        marginTop: "20px",
                                      }}
                                      variant="contained"
                                      type="reset"
                                      onClick={() =>
                                        handleClickOpendue(payment)
                                      }
                                    >
                                      DUE
                                    </Button>
                                    <DuePaymentModal
                                      open={opendue}
                                      onClose={handleClosedue}
                                      onSubmit={(formData) => {
                                        if (selectedPayment) {
                                          handleDuePayment({
                                            ...formData,
                                            member_id:
                                              selectedPayment.member_id, // Ensure selectedPayment is not null
                                            payment_id: selectedPayment.id, // Ensure selectedPayment is not null
                                            paying_amount:
                                              selectedPayment.due || 0, // Use a default value if due is null
                                            date_of_payment:
                                              selectedPayment.date_of_payment ||
                                              "", // Use a default value if date_of_payment is null
                                          });
                                        } else {
                                          console.error(
                                            "Selected payment is not available"
                                          );
                                        }
                                      }}
                                      dueAmount={
                                        selectedPayment
                                          ? selectedPayment.due
                                          : 0
                                      } // Use a default value if selectedPayment is null
                                      memberId={
                                        selectedPayment
                                          ? selectedPayment.member_id
                                          : 0
                                      } // Use a default value if selectedPayment is null
                                      paymentId={
                                        selectedPayment
                                          ? selectedPayment.payment_id
                                          : 0
                                      } // Use a default value if selectedPayment is null
                                    />
                                  </>
                            </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={4}
                              sx={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                paddingX: "20px",
                              }}
                            >
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "14px",
                                      textTransform: "none",
                                      color: "#fff",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Payment Mode
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.mode_of_payment}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    fontWeight="bold"
                                    sx={{
                                      fontSize: "16px",
                                      textTransform: "none",
                                      color: "#fff",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Payment Date
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {payment.date_of_payment}
                                  </Typography>
                                </Grid>
                              </Grid>
                              
                              <Grid item xs={3}>
                                
                                  <Button
                                     size="small"
                                    sx={{
                                      textTransform: "none",
                                      whiteSpace: "nowrap",
                                      fontWeight: 700,
                                    }}
                                    variant="contained"
                                    type="reset"
                                    onClick={openAddPTPModal}
                                  >
                                    Add PT
                                  </Button>
                               
                              </Grid>
                             <Grid item xs={3}>
                                <Grid item xs={12}>
                                  <Box>
                                    {payment.expired === "Yes" ? (
                                      <Button
                                      size="small"
                                        color="error"
                                        sx={{
                                          textTransform: "none",
                                          whiteSpace: "nowrap",
                                          fontWeight: 700,
                                         
                                        }}
                                        variant="contained"
                                        endIcon={<HourglassBottomIcon />}
                                      >
                                         Expired - {payment.expired_days}{" "}
                                        days ego
                                      </Button>
                                    ) : (
                                      <Button
                                        size="small"
                                        color="success"
                                        sx={{
                                          textTransform: "none",
                                          whiteSpace: "nowrap",
                                          fontWeight: 700,
                                        
                                        }}
                                        variant="contained"
                                        endIcon={<HourglassBottomIcon />}
                                      >
                                      {payment.days_left}{" "}
                                        left
                                      </Button>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                </Grid>
                              
                               
                      
                            </Grid>
                          </React.Fragment>
                        ))
                      ) : (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          {" "}
                          <Typography
                            sx={{ paddingTop: "100px" }}
                            variant="body1"
                          >
                            PT package is not taken.
                          </Typography>
                        </Box>
                      )}
                    </Card>
                  </Grid>
                  <Grid
  container
  justifyContent="center" // Centers horizontally
  alignItems="center" // Centers vertically
 
  item xs={6}
>
<style>
  {`
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes slideIn {
      from {
        transform: translateY(20px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  `}
</style>
<Card
  sx={{
    marginTop: "20px",
    marginBottom: "20px",
    background: "linear-gradient(135deg, #3a1c71, #d76d77, #ffaf7b)",
    borderRadius: "50%", // Makes the card a circle
    width: "300px", // Set width to define the size of the circle
    height: "300px", // Set height to define the size of the circle
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center", // Centers the content horizontally
    alignItems: "center", // Centers the content vertically
    textAlign: "center", // Center align the text
    transition: "transform 0.4s, box-shadow 0.4s, opacity 1s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 15px 40px rgba(0, 0, 0, 0.3)",
    },
    opacity: 0, // Start with hidden (for fade-in effect)
    animation: "fadeIn 2s forwards", // Fade-in animation
  }}
>
  {console.log("Yearly Membership:", yearlymembership)}

  {yearlymembership && yearlymembership.length > 0 ? (
    yearlymembership.map((payment, index) => (
      <React.Fragment key={index}>
        <Typography
          variant="h6"
          sx={{
            color: "white",
            fontWeight: "bold",
            fontSize: "20px", // Adjust text size to fit within the circle
            animation: "slideIn 1s ease-out", // Slide-in animation for text
            animationDelay: "0.5s", // Delay text animation
          }}
        >
          Membership will expire in:<br/> {payment.end_date}
        </Typography>
      </React.Fragment>
    ))
  ) : (
    <Typography
      variant="h6"
      sx={{
        color: "white",
        
        fontSize: "14px",
        animation: "slideIn 1s ease-out", // Slide-in animation for text
        animationDelay: "0.5s", // Delay text animation
      }}
    >
      No Membership Data Available
    </Typography>
  )}
</Card>
  
</Grid>

                </Grid>
              </div>
            </div>
          </div>
        )}
      </Box>
      <Dialog open={isPauseModal} onClose={closePauseModal}>
        <DialogTitle
          sx={{ paddingBottom: "0px", paddingTop: "32px", marginBottom: "0px" }}
        >
          Pause Confirmation
        </DialogTitle>
        <DialogContent sx={{ width: "500px" }}>
          <Typography> Are you sure you want to pause your Package?</Typography>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ marginTop: "13px", marginRight: "10px" }}>
              From Date :{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
              <DatePicker
                value={selectedDate} // Pass the selected date to the DatePicker component
                onChange={handleDateChange} // Call handleDateChange when date is selected
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
              fontWeight: 700,
            }}
            onClick={closePauseModal}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
              fontWeight: 700,
            }}
            onClick={handleClickPause}
          >
            Pause
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isResumeModal} onClose={closeResumeModal}>
        <DialogTitle
          sx={{ paddingBottom: "0px", paddingTop: "32px", marginBottom: "0px" }}
        >
          Resume Confirmation
        </DialogTitle>
        <DialogContent sx={{ width: "500px" }}>
          <Typography>Are you sure you want to resume your package?</Typography>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ marginTop: "13px", marginRight: "10px" }}>
              Resume Date :{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
              <DemoContainer components={["DatePicker"]} size="small">
                <DatePicker
                  value={resumeDate}
                  onChange={(date) => setResumeDate(date)}
                  slotProps={{ textField: { size: "small" } }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeResumeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickResume} disabled={!resumeDate}>
            {/* Add loading indicator if needed */}
            Resume
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isAddPTPModal}
        onClose={closeAddPTPModal}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "800px",
          },
        }}
      >
        <DialogTitle
          sx={{ paddingBottom: "0px", paddingTop: "32px", marginBottom: "0px" }}
        >
          Add Personal Trainer Package
        </DialogTitle>
        <DialogContent sx={{ minWidth: "800px" }}>
          
            <Grid
              container
              spacing={3}
              sx={{ marginTop: "10px", marginBottom: "5px", paddingX: "20px" }}
            >
              <Grid item xs={4}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    disabled
                    value={fetchdatarow.name}
                    fullWidth
                    placeholder=" Enter Name"
                  />
                </Grid>
              </Grid>
              {/* Second Row */}
              <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone"
                      disabled
                      value={fetchdatarow.phone}
                      fullWidth
                      placeholder=" Enter Phone"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField label="Paying Amount" fullWidth placeholder="Enter Paying Amount..." />
                                    </Grid>
                                </Grid>
                            </Grid> */}
              <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Choose Trainer Package
                      </InputLabel>
                      <Select
                        fullWidth
                        sx={{ minWidth: 120, height: "50px" }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Choose Trainer Package"
                        value={FormData.package}
                        error={submitted && !FormData.package} // Check for error
                        helperText={submitted && !FormData.package  &&
                          "Trainer  is required"} // Display error message
                        onChange={handlepayblevalueChange}
                      >
                   
                        {Dropdowndata.map((row) => (
                          <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

           
            
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "10px", marginBottom: "5px", paddingX: "20px" }}
            >
              <Grid item xs={4}>
                <Grid item xs={12}>
                  <TextField
                    label="Package Value"
                    fullWidth
                    placeholder=" Enter Package Value"
                    inputProps={{ readOnly: true }}
                    value={FormData.packagevalue}
                    error={submitted && !FormData.packagevalue} // Check for error
                    
                    helperText={submitted && !FormData.packagevalue &&
                      "Package Value is required"} // Display error message
                  
                    onChange={(e) =>
                      setFormData({ ...FormData, packagevalue: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Payble Amount"
                      type="number"
                      fullWidth
                      placeholder=" Enter Payble amount"
                      value={FormData.totalpaybleamount}
                      onChange={handleTotalPayableAmountChange}
                    
                      helperText={generateHelperText()}
                      error={FormData.totalpaybleamount > packageamount}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Discount"
                      fullWidth
                      type="number"
                      placeholder="Enter Discount"
                      value={FormData.discount}

                      onChange={(e) => handleDiscountChange(e)} // Call the function when discount changes
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "10px", marginBottom: "5px", paddingX: "20px" }}
            >
              <Grid item xs={4}>
                <Grid item xs={12}>
                  <TextField
                    label="Paying Amount"
                    fullWidth
                    value={FormData.payingamount}
                    error={submitted && !FormData.payingamount} // Check for error
                    helperText={submitted && !FormData.payingamount &&
                      "Paying Amount is required"} // Display error message
                  
                    onChange={(e) =>
                      setFormData({ ...FormData, payingamount: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              {/* Second Row */}
              <Grid item xs={4}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120 }} fullWidth error={submitted && !FormData.modeofpayment}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Mode of Payment
                      </InputLabel>
                      <Select
                        fullWidth
                        sx={{ minWidth: 120, height: "50px" }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Mode of Payment"
                        value={FormData.modeofpayment}
                        onChange={(e) =>
                          setFormData({
                            ...FormData,
                            modeofpayment: e.target.value,
                          })
                        }
                      >
                        {/* <MenuItem value="">
                      <em>Select Payment Type</em>
                    </MenuItem> */}
                        <MenuItem value="paytm">Paytm</MenuItem>
                        <MenuItem value="googlepay">Google Pay</MenuItem>
                        <MenuItem value="phonepe">PhonePe</MenuItem>
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="card"> Card</MenuItem>
                        {submitted && !FormData.modeofpayment && (
        <FormHelperText>This field is required</FormHelperText>
      )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item xs={12}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem components={["DatePicker"]}>
                        <DatePicker
                          value={FormData.dateofpayment}
                          onChange={(date) =>
                            setFormData({ ...FormData, dateofpayment: date })
                          } // Update 'dob' state in formData
                          label="Date of Payment"
                          format="DD/MM/YYYY"
                          clearable
                          slotProps={{
                            textField: {
                              error: submitted && !FormData.dateofpayment,
                              helperText:
                                submitted &&
                                !FormData.dateofpayment &&
                                "Date of Payment is required",
                            },
                          }}
                        />
                      </DemoItem>
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", marginBottom: "20px", paddingX: "20px" }}
            >
             
              {/* Second Row */}
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <React.Fragment>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem components={["DatePicker"]}>
                            <DatePicker
                              value={FormData.activationdate}
                              onChange={(date) =>
                                setFormData({
                                  ...FormData,
                                  activationdate: date,
                                })
                              } // Update 'dob' state in formData
                              label="Activation Date"
                              format="DD/MM/YYYY"
                              clearable
                              slotProps={{
                                textField: {
                                  error: submitted && !FormData.activationdate,
                                  helperText:
                                    submitted &&
                                    !FormData.activationdate &&
                                    "Activation Date is required",
                                },
                              }}
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Box>
                    </React.Fragment>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
              <Grid container spacing={2}>

  <Grid item xs={12}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Start Time"
        value={FormData.startTime ? dayjs(FormData.startTime) : null}
        onChange={(newValue) =>
          setFormData({ ...FormData, startTime: newValue })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={submitted && !FormData.startTime}
            helperText={submitted && !FormData.startTime && "Start time is required"}
          />
        )}
      />
    </LocalizationProvider>
  </Grid>


 
</Grid>
  </Grid>
 
<Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="End Time"
        value={FormData.endTime ? dayjs(FormData.endTime) : null}
        onChange={(newValue) =>
          setFormData({ ...FormData, endTime: newValue })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={submitted && !FormData.endTime}
            helperText={submitted && !FormData.endTime && "End time is required"}
          />
        )}
      />
    </LocalizationProvider>
  </Grid>
            </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Choose Trainer
                      </InputLabel>
                      <Select
                        fullWidth
                        sx={{ minWidth: 120, height: "55px" }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Choose Trainer"
                        value={FormData.trainer}
                        error={submitted && !FormData.trainer} // Check for error
                        helperText={submitted && !FormData.trainer  &&
                          "Package  is required"} // Display error message
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              trainer: e.target.value,
                            })
                          }
                      >
                        <MenuItem value="">
                      <em>Select Trainer</em>
                    </MenuItem>
                        {Dropdowndatatrainer.map((row) => (
                          <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid> 

                </Grid> 
        
        </DialogContent>
        <DialogActions>
          <Button
             sx={{ fontWeight: "bold", textTransform: "none" }}   variant="outlined"   startIcon={<CancelIcon />}
            onClick={closeAddPTPModal}
            color="primary"
          >
            Cancel
          </Button>
          {/* <Button color="primary" onClick={handleDelete}>
                                        Apply
                                    </Button> */}
          <Button
             variant="contained"
             sx={{ fontWeight: "bold", textTransform: "none" }}
            
             startIcon={<CheckCircleIcon />}
            onClick={handleClickAddPTP}
            // backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}
          >
            {/* {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} */}
            Add PT
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isAddYMPModal}
        onClose={closeAddYMPModal}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "800px",
          },
        }}
      >
        <DialogTitle
          sx={{ paddingBottom: "0px", paddingTop: "32px", marginBottom: "0px" }}
        >
          Add Yearly Membership Package
        </DialogTitle>

        <DialogContent sx={{ minWidth: "800px" }}>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: "20px", paddingX: "20px" }}
          >
            <Grid item xs={4}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  value={fetchdatarow.name}
                  fullWidth
                  placeholder=" Enter Name"
                  size="small"
                  sx={{ marginTop: "10px" }}
                />
              </Grid>
            </Grid>
            {/* Second Row */}
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DemoContainer components={["DatePicker"]} size="small">
                      <Box sx={{ minWidth: "100px" }}>
                        <DatePicker
                          value={startDate}
                          onChange={handleStartDateChange}
                          slotProps={{ textField: { size: "small" } }}
                          label="Start Date"
                          format="DD/MM/YYYY"
                          clearable
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DemoContainer components={["DatePicker"]} size="small">
                      <Box sx={{ minWidth: "100px" }}>
                        <DatePicker
                          value={dayjs(endDate)}
                          slotProps={{ textField: { size: "small" } }}
                          label="Expired Date"
                          format="DD/MM/YYYY"
                          clearable
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddYMPModal}     sx={{ fontWeight: "bold", textTransform: "none" }}   variant="outlined"   startIcon={<CancelIcon />} color="primary">
            Cancel
          </Button>
          
         

          <Button
            variant="contained"
            sx={{ fontWeight: "bold", textTransform: "none" }}
           
            startIcon={<CheckCircleIcon />}
            onClick={handleClickAddYMP}
            color="primary"
          >
              Add
          </Button>

        </DialogActions>
      </Dialog>

      <Dialog
        open={useopen}
        onClose={handlerenwalclose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Renew Package</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6} sx={{ marginTop: "20px" }}>
              <FormControl fullWidth>
                <InputLabel id="package-label">Package</InputLabel>
                <Select
                  labelId="package-label"
                  id="package"
                  label="Package"
                  value={formdueData.package_id}
                  onChange={handlepayblevalueChangemember}
                  fullWidth
                >
                  {Dropdowndatamember.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {membershipExpired && (
  <>
    <Grid item xs={6} sx={{ marginTop: "20px" }}>
      <FormControl fullWidth>
        <InputLabel id="membership-label">Membership</InputLabel>
        <Select
          labelId="membership-label"
          id="membership"
          label="Membership"
          value={formdueData.membership}
          onChange={handlepayblevalueadmissionrepeat}
          placeholder="Choose Membership"
          fullWidth
        >
          {Dropdownadmissiondata.map((row) => (
            <MenuItem key={row.id} value={row.id}>
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>

    <Grid item xs={6} sx={{ marginTop: "20px" }}>
      <TextField
       labelId="membership-Value"
          id="membership-value"
        label="Membership Value"
        inputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
       
        value={formdueData.membershipvalue}
        onChange={(e) =>
          setFormdueData({
            ...formdueData,
            membershipvalue: e.target.value,
          })
        }
      />
    </Grid>
  </>
)}

            <Grid item xs={6} sx={{ marginTop: "20px" }}>
              <TextField
                label="Total Payable Amount"
                fullWidth
                name="total_payble_amount"
                value={formdueData.total_payble_amount}
                onChange={handleChangerenwal}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="number"
                label="Payable Amount"
                fullWidth
                name="payble_amount"
                value={formdueData.payble_amount}
                onChange={handleChangerenwal}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Offer"
                fullWidth
                name="offer"
                value={formdueData.offer}
                onChange={handleChangerenwal}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="number"
                label="Paying Amount"
                fullWidth
                name="paying_amount"
                value={formdueData.paying_amount}
                onChange={handleChangerenwal}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 120 }} fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Mode of Payment
                </InputLabel>
                <Select
                  fullWidth
                  sx={{ minWidth: 120, height: "50px" }}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Mode of Payment"
                  value={formdueData.mode_of_payment}
                  name="mode_of_payment"  // Add name attribute to identify the field
                  onChange={handleChangePaymentMode}
                >
                  {/* <MenuItem value="">
                      <em>Select Payment Type</em>
                    </MenuItem> */}
                  <MenuItem value="paytm">Paytm</MenuItem>
                  <MenuItem value="googlepay">Google Pay</MenuItem>
                  <MenuItem value="phonepe">PhonePe</MenuItem>
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="card"> Card</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Date of Payment"
                fullWidth
                name="date_of_payment"
                type="date"
                value={formdueData.date_of_payment}
                onChange={handleChangerenwal}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Start Date"
                fullWidth
                name="start_date"
                type="date"
                value={formdueData.start_date}
                onChange={handleChangerenwal}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: getMinStartDate(), // Disable past dates based on conditions
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CancelIcon />}
            onClick={handlerenwalclose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ fontWeight: "bold", textTransform: "none" }}
            disabled={renwalloading}
            startIcon={<CheckCircleIcon />}
            onClick={handleSave}
            color="primary"
          >
            {renwalloading ? (
              <>
                <CircularProgress size={24} sx={{ marginRight: "8px" }} />{" "}
                {/* Show loader next to the text */}
                Save
              </>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <DetailDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        data={fetchdatarow}
      />
      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={repeatOpen}
        onClose={repeatHandleClose}
        aria-labelledby="repeat-package-dialog"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="repeat-package-dialog">
          Repeat Package for Member
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="body1" gutterBottom>
            Enter offer details:
          </Typography>

          <TextField
            fullWidth
            label="Offer"
            type="number"
            value={repeatOffer}
            onChange={(e) => setRepeatOffer(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          <FormControl sx={{ minWidth: 120 }} fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Mode of Payment
            </InputLabel>
            <Select
              fullWidth
              sx={{ minWidth: 120, height: "50px" }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Mode of Payment"
              value={repeatModeOfPayment}
              onChange={(e) => setRepeatModeOfPayment(e.target.value)}
            >
              {/* <MenuItem value="">
                      <em>Select Payment Type</em>
                    </MenuItem> */}
              <MenuItem value="paytm">Paytm</MenuItem>
              <MenuItem value="googlepay">Google Pay</MenuItem>
              <MenuItem value="phonepe">PhonePe</MenuItem>
              <MenuItem value="cash">Cash</MenuItem>
              <MenuItem value="card"> Card</MenuItem>
            </Select>
          </FormControl>

          {membershipExpired && (
      <>
          <FormControl fullWidth   sx={{ marginTop: 2 }}>
        <InputLabel id="membership-label">Membership</InputLabel>
        <Select
          labelId="membership-label"
          id="membership-label"
          label="Membership"
          value={renewalType}
          onChange={handlepayblevalueadmissionChange}
          placeholder="Choose Membership"
          fullWidth
         
        >
          {Dropdownadmissiondata.map((row) => (
            <MenuItem key={row.id} value={row.id}>
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  <TextField
        type="number"
         label="Renewal Amount"
        inputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        value={renewalAmount}
        sx={{ marginTop: 2 }}
        onChange={(e) => setRenewalAmount(e.target.value)}
      />
     
      </>
    )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CancelIcon />}
            onClick={repeatHandleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ fontWeight: "bold", textTransform: "none" }}
            disabled={renwalloading}
            startIcon={<CheckCircleIcon />}
            onClick={handlerepeatSubmit}
            color="primary"
          >
            {renwalloading ? (
              <>
                <CircularProgress size={24} sx={{ marginRight: "8px" }} />{" "}
                {/* Show loader next to the text */}
                Save
              </>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <ChangePackage
        open={openChangeModal}
        onClose={handleCloseChangeModal}
        packageData={selectedPaymentData}
        member_id={conversation_id}
        fetchData={fetchData}
      />
       <ChangePackagePt
         open={openChangeModalpt}
         onClose={handleCloseChangeModalpt}
        packageData={selectedPaymentDataPt}
        member_id={conversation_id}
        fetchData={fetchData}
      />
       <Dialog
  maxWidth="sm"
  fullWidth
  open={isChangeTrainerDialogOpen}
  onClose={handleClosetrainerDialog}
  aria-labelledby="change-trainer-dialog-title"
>
  <DialogTitle id="change-trainer-dialog-title">Change Trainer</DialogTitle>
  <DialogContent>
    {/* Single Dropdown */}
    <FormControl fullWidth sx={{ marginTop: "10px" }}>
      <InputLabel id="demo-simple-select-helper-label">Choose Trainer</InputLabel>
      <Select
        fullWidth
        sx={{ minWidth: 120, height: "55px" }}
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={trainer}
        label="Choose Trainer"
        onChange={(e) => setTrainer(e.target.value)} 
      >
        <MenuItem value="">
          <em>Select Trainer</em>
        </MenuItem>
        {Dropdowndatatrainer.map((row) => (
          <MenuItem key={row.id} value={row.id}>
            {row.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button        variant="outlined"
            sx={{ fontWeight: "bold", textTransform: "none" }}     startIcon={<CancelIcon />} onClick={handleClosetrainerDialog} color="primary">
      Cancel
    </Button>
    <Button
       variant="contained"
       sx={{ fontWeight: "bold", textTransform: "none" }}
      onClick={() => {
        console.log("Trainer changed to:", trainer);
        handleTrainerChangeapi();
      }}
      startIcon={<CheckCircleIcon />}
      color="primary"
    >
      Save
    </Button>
  </DialogActions>
</Dialog>

    </Box>
    
  );
}

export default MemberDetails;
