
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import IconButton from "@mui/material/IconButton";

import Typography from "@mui/material/Typography";
import Header from '../Includes/Header';
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { DialogContentText } from '@mui/material';
import { Card, Button, Grid, TextField,Snackbar, FormControl,Alert, InputLabel, Select, MenuItem, FormHelperText, Paper } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DemoContainer,DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from 'dayjs';
import json2mq from "json2mq";
import {
  retrieveEmployeesDetails,
  DeleteEmployeesDetails,
  AutocompleteEmployeesDetails,
  UpdateEmployees
} from "../Apihelper/ApiEmployeesDetails";

import {
 
  AutocompleteAdminUser,
  DeleteAdminUser,
  StatusAdminUser,
  UpdateAdminUser
} from "../Apihelper/ApiHelperUser";


import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function EmployeesDetail(props) {
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [status, setstatus] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
const [isActive, setIsActive] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [Role, setRole] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [options, setOptions] = useState([]);
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default to 'success'
  const [fetchdatarow, setetchdatarow] = useState([]); // Default to 'success'
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const dateRef = useRef();
  const [age, setAge] = React.useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  
  const [editedData, setEditedData] = useState({
    id: '',
    address: '',
    fullname: '',
    email: '',
    phone: '',
    designation: '',
    salary:'',
    bloodgroup:'',
    joiningdate: new Date()
  });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const handleToastClose = () => {
    setOpenToast(false);
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }


  const handleToggle = (id,status) => {
    setOpenConfirmation(true);
   const newStatus = status === 1 ? 0 : 1;
  setstatus(newStatus);
  setDeleteConfirmationId(id);
  setIsActive(newStatus === 0);

  };

  const handleConfirm = async() => {
    // setIsActive(!isActive);
    // setOpenConfirmation(false);

    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; 
     
      
        const res = await StatusAdminUser(userToken,userId,status, deleteConfirmationId);

        if (res.code == "200") {
            handleSnackbarOpen(res.message, 'success');
            setIsActive(!isActive);
            setOpenConfirmation(false);
            setOpenDeleteConfirmation(false);
            fetchData();
        } else {
            handleSnackbarOpen(res.message, 'error');
            //  console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
  };
  const handleCancel = () => {
    setOpenConfirmation(false);
  };
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fetchData = async (value = '') => {
    setLoading(true);
    setHasMore(true);
    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata
        const searchText = value; // Example search text
        const limit = 10; // Example limit
        const index = 0; // Example index
        const res = await retrieveEmployeesDetails(userToken, userId, searchText, limit, index);

        if (res.code == "200") {
            setetchdatarow(res.data)
            console.log(res.data);
            setLoading(false);
        } else {
            handleSnackbarOpen(res.message, 'error');
            // console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
};

  useEffect(() => {
   
    fetchData();
}, []); 


const handleLoadMore = async () => {
  // Calculate the next index based on the number of items loaded so far
  const nextIndex = fetchdatarow.length

  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);

  try {
    const userToken = userDataObj.token;
    const userId = userDataObj.id;
    const searchText = ''
    const limit = 10;

    const res = await retrieveEmployeesDetails(userToken, userId, searchText, limit, nextIndex);

    if (res.code == "200") {
      // Append the newly loaded data to the existing data
      setetchdatarow((prevResults) => [...prevResults, ...res.data]);

      // Check if there are more results available
      if (res.data.length >= 0 && res.data.length < 10) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
};


const handleSearchChange = (event, value) => {
  const searchText = value || ''; // If value is undefined or empty, set searchText to empty string
  // setSearchText(searchText);
  // Call fetchData function here with the updated searchText
  fetchData(searchText);
};

const handleDelete = (id) => {
  handleClose();
  setDeleteConfirmationId(id);
  setOpenDeleteConfirmation(true,id);
};

const handleConfirmDelete =async (id) => {
  // setSnackbarMessage('package deleted successfully');
  // setSnackbarSeverity('error');
  // setOpenDeleteConfirmation(false);
  // setOpenToast(true); // Show toast message

  if (deleteConfirmationId !== null) {
   
      try {
          const userData = localStorage.getItem("userdata");
          const userDataObj = JSON.parse(userData);
          const userToken = userDataObj.token;
          const userId = userDataObj.id; 
       
        
          const res = await DeleteEmployeesDetails(userToken, userId, deleteConfirmationId);
  
          if (res.code == "200") {
              handleSnackbarOpen(res.message, 'success');
              setOpenDeleteConfirmation(false);
              fetchData()
          } else {
              handleSnackbarOpen(res.message, 'error');
              //  console.error(error); // Handle error
          }
      } catch (error) {
          console.error(error);
          // Handle errors
      }
  }
  };


const handleCancelDelete = () => {
  setOpenDeleteConfirmation(false);
};
const handleInputChange = async (value) => {
  if (value.length >= 3) {
  try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
    
      const res = await AutocompleteEmployeesDetails(userToken, userId, searchText);

      if (res.code == "200") {
          const names = res.data.map(item => item.search_data);
          setOptions(names);
      } else {
          handleSnackbarOpen(res.message, 'error');
          //  console.error(error); // Handle error
      }
  } catch (error) {
      console.error(error);
      // Handle errors
  }
}
};
const handleEditModalOpen = (id, fullname, email, phone, address, designation, salary, blood_group, joining_date) => {
  const formattedJoiningDate = (dayjs(joining_date)); // Assuming joining_date is a valid date string
  console.log(formattedJoiningDate);
  // setstartDate(dayjs(joining_date));
  setEditedData({
    id: id,
    fullname: fullname,
    email: email,
    phone: phone,
    address: address,
    designation: designation,
    salary: salary,
    bloodgroup: blood_group,
    joiningdate: formattedJoiningDate
  });
  setEditModalOpen(true);
};

const handleEditModalClose = () => {
  setEditModalOpen(false);
};

const handlePasswordChange = (event) => {
  setPassword(event.target.value);
  // Reset confirm password error when password is changed
  setConfirmPasswordError('');
};

const handleConfirmPasswordChange = (event) => {
  setConfirmPassword(event.target.value);
  // Check if confirm password matches password
  if (event.target.value !== password) {
    setConfirmPasswordError('Passwords do not match');
  } else {
    setConfirmPasswordError('');
  }
};

const handleEditSubmit = async () => {
  if (password !== confirmPassword) {
    setConfirmPasswordError('Passwords do not match');
    return;
  }

  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);
  const userToken = userDataObj.token;

  try {
    const res = await UpdateEmployees(
      editedData.id, // Assuming id is needed for updating
      userToken,
      editedData, // Pass the entire editedData object
      confirmPassword // Pass confirmPassword separately
    );

    if (res.code == "200") {
      handleSnackbarOpen(res.message, 'success');
      setConfirmPassword('');
      setPassword('');
      setEditedData({
        id: '',
        address: '',
    fullname: '',
    email: '',
    phone: '',
    designation: '',
    salary:'',
    bloodgroup:'',
    joiningdate:null
    });
      fetchData();
    } else {
      handleSnackbarOpen(res.message, 'error');
    }
  } catch (error) {
    console.error(error);
  }

  setEditModalOpen(false);
};
useEffect(() => {
  const storedData = localStorage.getItem("userdata");
  if (!storedData) {
    // User is not logged in, no need to redirect
    navigate('/');
  }
  
}, []);
const handleRoleChange = (event) => {
  setRole(event.target.value);
};
  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: "#EFEDF1" }}>
      <CssBaseline />
      <Box  >
        <Header />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px', }}>
        <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
          <div style={{ flex: 1, marginTop: '15px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box >
                <Typography variant="h5" component="h6">
                Employees Details
                </Typography>
              </Box>
              <Box>
                <Button variant="contained" onClick={() => navigate('/addemployees')} sx={{ textTransform: 'none', whiteSpace: 'nowrap', fontWeight: 700 }}>New Employees
                </Button>
              </Box>

            </Box>


          
              <Box>
              <Autocomplete
    freeSolo
    id="free-solo-2-demo"
    disableClearable
    options={options}
    onInputChange={(event, value) => handleInputChange(value)} // Pass searchText to handleInputChange
    onChange={(event, value) => handleSearchChange(event, value)}
    renderInput={(params) => (
      
      <TextField
        {...params}
        size="small"
        sx={{
          width: 300,
          '& .MuiInputLabel-root': {
            textAlign: 'center', // Center align the label text
          },
        }}
        label="Search input"
        InputProps={{
          ...params.InputProps,
          type: 'search',
        }}
      />
    )}
    sx={{
      '& .MuiInputBase-root': {
        alignItems: 'center',
        height: '40px',
      },
    }}
  />
              </Box>
             
            


              <TableContainer component={Paper}   sx={{
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                overflow: "hidden",
                margin: "20px 0",
              }}>
      {loading ? ( // Display spinner if loading is true
        <div style={{ textAlign: 'center', margin: '50px auto' }}>
          <CircularProgress />
        </div>
      ) : ( // Display table if loading is false
        <Table size="small" aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#B1ADAD' }}>
            <TableRow    sx={{
                        backgroundColor: "#2f80ed",
                      }}>
              <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}>Name</TableCell>
              <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }} align="left">Email</TableCell>
                        
              <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }} align="left">Phone</TableCell>
              <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }} align="left">Designation</TableCell>
              <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }} align="left">Salary&nbsp;</TableCell>
              <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }} align="left">Joining Date&nbsp;</TableCell>
              <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}align="left">   Blood Group&nbsp;</TableCell>
            <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }} align="left">Card Number</TableCell>
              <TableCell   sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }} align="left">Action&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchdatarow.map((row, indexx) => (
              <TableRow key={indexx}  sx={{
                "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                "&:hover": {
                  backgroundColor: "#e3f2fd",
                  cursor: "pointer",
                },
              }}>
                <TableCell>
                  <Typography>{row.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{row.email}</Typography>
                </TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left">{row.designation}</TableCell>
                <TableCell align="left">{row.salary}</TableCell>
                <TableCell align="left">{row.joining_date}</TableCell>
                <TableCell align="left">{row.blood_group}</TableCell>
                <TableCell align="left">{row.card_number}</TableCell>
                <TableCell>
                  <>
                  <IconButton
                  color='primary'
  aria-label="edit"
  onClick={() => handleEditModalOpen(
    row.id,
    row.name,
    row.email,
    row.phone,
    row.address,
    row.designation,
   
    row.salary,
    row.blood_group,
    row.joining_date,
  )}
>
  <EditIcon />
</IconButton>
<IconButton
 color='error'
  aria-label="delete"
  onClick={() => handleDelete(row.id)} // Pass the id or any other identifier if needed
>
  <DeleteIcon />
</IconButton> 
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
             <div style={{ textAlign: "center", marginTop: "10px" }}>
             <Button
  variant="contained"
  color="primary"
  onClick={handleLoadMore}
 
  disabled={!hasMore || (fetchdatarow.length < 10 && fetchdatarow.length !== 0)}
  sx={{ fontWeight: 'bold', textTransform: 'none' }}
>
  {fetchdatarow.length === 0 || fetchdatarow.length < 10 ? 'No More Records' : 'Load More'}
</Button>


      </div>
          </div>

        </div>

      </Box>
      <Dialog open={openDeleteConfirmation} onClose={handleCancelDelete} fullWidth="sm">
      
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this package?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<CancelIcon />} sx={{fontWeight:'bold',textTransform:'none'}} onClick={handleCancelDelete}>Cancel</Button>
        <Button onClick={handleConfirmDelete}  sx={{fontWeight:'bold',textTransform:'none'}} variant="contained" startIcon={<DeleteIcon />} color="error"> Delete</Button>
      </DialogActions>
    </Dialog>
    <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert  variant="filled" onClose={handleToastClose} severity={snackbarSeverity}>
        {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openConfirmation} onClose={handleCancel}>
        <DialogTitle>{isActive ? 'Deactivate' : 'Activate'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isActive
              ? 'Are you sure you want to deactivate this item?'
              : 'Are you sure you want to activate this item?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  sx={{fontWeight:'bold',textTransform:'none'}} startIcon={<CancelIcon />}onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm}sx={{fontWeight:'bold',textTransform:'none'}} variant="contained"startIcon={<CheckCircleIcon />} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editModalOpen} onClose={handleEditModalClose}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {/* Edit input fields */}
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              type="text"
              value={editedData.fullname}
          
              onChange={(e) => setEditedData({ ...editedData, fullname: e.target.value.toUpperCase() })}

            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Email"
              type="email"
              value={editedData.email}
              onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone"
             
              type="number"
              value={editedData.phone}
              onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Address"
              type="text"
              value={editedData.address}
              onChange={(e) => setEditedData({ ...editedData, address: e.target.value.toUpperCase() })}
              

            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-helper-label">Designation</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="designation"
              label="Designation"
              value={editedData.designation}
              onChange={(e) => setEditedData({ ...editedData, designation: e.target.value })}
            >
                <MenuItem value="">Select Designation</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="trainer">Trainer</MenuItem>
              <MenuItem value="perfectclining">PERFECT CLINING</MenuItem>
              <MenuItem value="supervisor">Supervisor</MenuItem>
              <MenuItem value="pujarinana">Pujari Nana</MenuItem>
            </Select>
          </FormControl>

         
        

          {/* Password and Confirm Password fields */}
          <FormControl fullWidth margin="normal">
            <TextField
              label="Salary/Month"
              type="number"
           
              value={editedData.salary}
              onChange={(e) => setEditedData({ ...editedData, salary: e.target.value })}
             
              required
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Blood Group"
             
              value={editedData.bloodgroup}
          
              onChange={(e) => setEditedData({ ...editedData, bloodgroup: e.target.value.toUpperCase() })}
              required
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoItem  components={['DatePicker']}>
          <DatePicker
          
                        value={editedData.joiningdate}
                        onChange={(date) => setEditedData({ ...editedData, joiningdate: date })}  
           label="joining Date"
            format="DD/MM/YYYY"
            clearable
           
           
          />
                </DemoItem>
        </LocalizationProvider>
        </FormControl>
        </DialogContent>
        <DialogActions>
         

          <Button sx={{ fontWeight: 'bold', textTransform: 'none' }}  startIcon={<CancelIcon />}onClick={handleEditModalClose}>Cancel</Button>
          <Button sx={{ fontWeight: 'bold', textTransform: 'none' }} startIcon={<CheckCircleIcon />}  onClick={handleEditSubmit} variant="contained" color="primary">
          Update</Button>
        </DialogActions>
      </Dialog>
    </Box>



  );
}

export default EmployeesDetail;