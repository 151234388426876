import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, Button,
  TextField, Grid, FormControl, Box,InputLabel, FormHelperText,Select, MenuItem, Snackbar, Alert,DialogActions,
} from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PtPackageDropdown, PtTrainerDropdown,TrainerPaymentcHANGE, PtPackageDropdownvalueCHANGE } from "../../Apihelper/MemberDashboard.js";
import dayjs from "dayjs";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TimePicker } from "@mui/x-date-pickers";
const ChangePackagePt = ({ open, onClose, packageData, member_id, fetchData }) => {
  const [FormData, setFormData] = useState({
    member_id: "",
    package: "",
    packagevalue: "",
    totalpaybleamount: "",
    discount: "",
    payableamount: "",
    modeofpayment: "",
    payingamount: "",
    activationdate: null,
    dateofpayment: null,
    trainer: "",
    slot: "",
    startTime: null,  // Added startTime
    endTime: null,    // Added endTime
    
  });
  const [renwalloading, setRenwalLoading] = useState(false);
  const [Dropdowndata, setDropdowndata] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [packageamount, setPackageamount] = useState("");
  const [Dropdowndatatrainer, setDropdowndatatrainer] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const generateHelperText = () => {
    return FormData.totalpaybleamount > packageamount
      ? "Total payable amount cannot exceed the package value"
      : "";
  };
  useEffect(() => {
    fetchDropdowndata();
    fetchDropdowndatatrainer();
    console.log("Package Data:", packageData);
  }, []);
  const fetchDropdowndata = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PtTrainerDropdown(userToken, userId);
        
      if (res.code == "200") {
        setDropdowndatatrainer(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };

  const fetchDropdowndatatrainer = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PtPackageDropdown(userToken, userId);

      if (res.code == "200") {
        setDropdowndata(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };

  const handleToastClose = () => setOpenToast(false);

  const handlepayblevalueChange = async (e) => {
    const selectedPackageId = e.target.value;
    setFormData({ ...FormData, package: selectedPackageId });

    try {
      const userData = JSON.parse(localStorage.getItem("userdata"));
      const userToken = userData.token;
      const Userid = userData.id;
      const res = await PtPackageDropdownvalueCHANGE(userToken,Userid, selectedPackageId,member_id);

      if (res.code == "200") {
        const packageAmount = res.data.package_amount;
        setFormData((prevState) => ({ ...prevState, packagevalue: packageAmount }));
        setFormData((prevState) => ({ ...prevState, totalpaybleamount: packageAmount }));
        setPackageamount(packageAmount);
      } else {
        handleSnackbarOpen(res.message, 'error');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      handleSnackbarOpen("Failed to fetch package value.", "error");
    }
  };

  const handleTotalPayableAmountChange = (e) => {
    const enteredValue = e.target.value;
    const totalPayableAmount = enteredValue > packageamount ? packageamount : enteredValue;
    setFormData({ ...FormData, totalpaybleamount: totalPayableAmount });
  };

  const handleDiscountChange = (e) => {
    const discount = parseFloat(e.target.value) || 0;
    const newPayableAmount = FormData.totalpaybleamount - discount;
    setFormData({ ...FormData, discount, payingamount: newPayableAmount });
  };

  const handleClickcHANGE = async () => {
    setSubmitted(true);
    const updatedFormData = {
      ...FormData,
      startTime: FormData.startTime ? FormData.startTime.format("HH:mm") : "",
      endTime: FormData.endTime ? FormData.endTime.format("HH:mm") : "",
      slot:
        FormData.startTime && FormData.endTime
          ? `${FormData.startTime.format("HH:mm")}-${FormData.endTime.format(
              "HH:mm"
            )}`
          : "",
    };
  
    // Set the updated FormData to state
    setFormData(updatedFormData);
    const requiredFields = ["packagevalue", "package", "totalpaybleamount", "modeofpayment", "payingamount", "activationdate", "dateofpayment", "trainer", "slot", "startTime",  
      "endTime",];
    const emptyFields = requiredFields.filter((field) => !FormData[field]);

    if (emptyFields.length > 0) {
      handleSnackbarOpen("Please fill all required fields.", "error");
      return;
    }

    try {
      const userData = JSON.parse(localStorage.getItem("userdata"));
      const userToken = userData.token;
      const res = await TrainerPaymentcHANGE(userToken, member_id,FormData);

      if (res.code == "200") {
        handleSnackbarOpen("Added successfully", "success");
        setFormData({
          member_id: "",
          package: "",
          packagevalue: "",
          totalpaybleamount: "",
          discount: "",
          payableamount: "",
          modeofpayment: "",
          payingamount: "",
          activationdate: null,
          dateofpayment: null,
          trainer: "",
          slot: "",
          startTime: null,  // Added startTime
    endTime: null,    // Added endTime
        });
        fetchData();
      } else {
        handleSnackbarOpen(res.message, "error");
      }
    } catch (error) {
      console.error(error);
      handleSnackbarOpen("An error occurred. Please try again.", "error");
    }
  };

  return (
    <>
         <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "800px",
          },
        }}
      >
        <DialogTitle
          sx={{ paddingBottom: "0px", paddingTop: "32px", marginBottom: "0px" }}
        >
        Change Trainer Package
        </DialogTitle>
        <DialogContent sx={{ minWidth: "800px" }}>
          
            <Grid
              container
              spacing={3}
              sx={{ marginTop: "10px", marginBottom: "5px", paddingX: "20px" }}
            >
             
            
              <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Choose Trainer Package
                      </InputLabel>
                      <Select
                        fullWidth
                        sx={{ minWidth: 120, height: "50px" }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Choose Trainer Package"
                        value={FormData.package}
                        error={submitted && !FormData.package} // Check for error
                        helperText={submitted && !FormData.package  &&
                          "Trainer  is required"} // Display error message
                        onChange={handlepayblevalueChange}
                      >
                   
                        {Dropdowndata.map((row) => (
                          <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item xs={12}>
                  <TextField
                    label="Package Value"
                    fullWidth
                    placeholder=" Enter Package Value"
                    inputProps={{ readOnly: true }}
                    value={FormData.packagevalue}
                    error={submitted && !FormData.packagevalue} // Check for error
                    
                    helperText={submitted && !FormData.packagevalue &&
                      "Package Value is required"} // Display error message
                  
                    onChange={(e) =>
                      setFormData({ ...FormData, packagevalue: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Payble Amount"
                      type="number"
                      fullWidth
                      placeholder=" Enter Payble amount"
                      value={FormData.totalpaybleamount}
                      onChange={handleTotalPayableAmountChange}
                    
                      helperText={generateHelperText()}
                      error={FormData.totalpaybleamount > packageamount}
                    />
                  </Grid>
                </Grid>
              </Grid>
           
            
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "10px", marginBottom: "5px", paddingX: "20px" }}
            >
            
              <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Discount"
                      fullWidth
                      type="number"
                      placeholder="Enter Discount"
                      value={FormData.discount}

                      onChange={(e) => handleDiscountChange(e)} // Call the function when discount changes
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid item xs={12}>
                  <TextField
                    label="Paying Amount"
                    fullWidth
                    value={FormData.payingamount}
                    error={submitted && !FormData.payingamount} // Check for error
                    helperText={submitted && !FormData.payingamount &&
                      "Paying Amount is required"} // Display error message
                  
                    onChange={(e) =>
                      setFormData({ ...FormData, payingamount: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              {/* Second Row */}
              <Grid item xs={4}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120 }} fullWidth error={submitted && !FormData.modeofpayment}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Mode of Payment
                      </InputLabel>
                      <Select
                        fullWidth
                        sx={{ minWidth: 120, height: "50px" }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Mode of Payment"
                        value={FormData.modeofpayment}
                        onChange={(e) =>
                          setFormData({
                            ...FormData,
                            modeofpayment: e.target.value,
                          })
                        }
                      >
                        {/* <MenuItem value="">
                      <em>Select Payment Type</em>
                    </MenuItem> */}
                        <MenuItem value="paytm">Paytm</MenuItem>
                        <MenuItem value="googlepay">Google Pay</MenuItem>
                        <MenuItem value="phonepe">PhonePe</MenuItem>
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="card"> Card</MenuItem>
                        {submitted && !FormData.modeofpayment && (
        <FormHelperText>This field is required</FormHelperText>
      )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "10px", marginBottom: "5px", paddingX: "20px" }}
            >
            
              <Grid item xs={4}>
                <Grid item xs={12}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem components={["DatePicker"]}>
                        <DatePicker
                          value={FormData.dateofpayment}
                          onChange={(date) =>
                            setFormData({ ...FormData, dateofpayment: date })
                          } // Update 'dob' state in formData
                          label="Date of Payment"
                          format="DD/MM/YYYY"
                          clearable
                          slotProps={{
                            textField: {
                              error: submitted && !FormData.dateofpayment,
                              helperText:
                                submitted &&
                                !FormData.dateofpayment &&
                                "Date of Payment is required",
                            },
                          }}
                        />
                      </DemoItem>
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
             
              <Grid item xs={4}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Start Time"
        value={FormData.startTime ? dayjs(FormData.startTime) : null}
        onChange={(newValue) =>
          setFormData({ ...FormData, startTime: newValue })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={submitted && !FormData.startTime}
            helperText={submitted && !FormData.startTime && "Start time is required"}
          />
        )}
      />
    </LocalizationProvider>
  </Grid>
</Grid>
  </Grid>
  <Grid item xs={4}>
  <Grid item xs={12}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="End Time"
        value={FormData.endTime ? dayjs(FormData.endTime) : null}
        onChange={(newValue) =>
          setFormData({ ...FormData, endTime: newValue })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={submitted && !FormData.endTime}
            helperText={submitted && !FormData.endTime && "End time is required"}
          />
        )}
      />
    </LocalizationProvider>
  </Grid>
  </Grid>
  </Grid>
  
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", marginBottom: "20px", paddingX: "20px" }}
            >
             
             <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <React.Fragment>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem components={["DatePicker"]}>
                            <DatePicker
                              value={FormData.activationdate}
                              onChange={(date) =>
                                setFormData({
                                  ...FormData,
                                  activationdate: date,
                                })
                              } // Update 'dob' state in formData
                              label="Activation Date"
                              format="DD/MM/YYYY"
                              clearable
                              slotProps={{
                                textField: {
                                  error: submitted && !FormData.activationdate,
                                  helperText:
                                    submitted &&
                                    !FormData.activationdate &&
                                    "Activation Date is required",
                                },
                              }}
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Box>
                    </React.Fragment>
                  </Grid>
                </Grid>
              </Grid>
          
  <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Choose Trainer
                      </InputLabel>
                      <Select
                        fullWidth
                        sx={{ minWidth: 120, height: "55px" }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Choose Trainer"
                        value={FormData.trainer}
                        error={submitted && !FormData.trainer} // Check for error
                        helperText={submitted && !FormData.trainer  &&
                          "Package  is required"} // Display error message
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              trainer: e.target.value,
                            })
                          }
                      >
                        <MenuItem value="">
                      <em>Select Trainer</em>
                    </MenuItem>
                        {Dropdowndatatrainer.map((row) => (
                          <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

 
            </Grid>
           
        
        </DialogContent>
        <DialogActions>
          <Button
             sx={{ fontWeight: "bold", textTransform: "none" }}   variant="outlined"   startIcon={<CancelIcon />}
            onClick={onClose}
            color="primary"
          >
            Cancel
          </Button>
          {/* <Button color="primary" onClick={handleDelete}>
                                        Apply
                                    </Button> */}
          <Button
             variant="contained"
             sx={{ fontWeight: "bold", textTransform: "none" }}
            
             startIcon={<CheckCircleIcon />}
            onClick={handleClickcHANGE}
            // backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}
          >
            {/* {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} */}
            Change PT
          </Button>
        </DialogActions>
      </Dialog>

<Snackbar
open={openToast}
autoHideDuration={6000}
onClose={handleToastClose}
anchorOrigin={{ vertical: "top", horizontal: "right" }}
>
<Alert
  variant="filled"
  onClose={handleToastClose}
  severity={snackbarSeverity}
>
  {snackbarMessage}
</Alert>
</Snackbar>
</>
  );
};

export default ChangePackagePt;
